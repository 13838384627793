import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Homepage from './Pages/Homepage/Homepage';
import animateCursor from './Components/Homepage/Cursor'
import Work from './Pages/Work/Work';
import WorkUI from './Pages/WorkUI/WorkUI'
import WorkFrontend from './Pages/WorkFrontend/WorkFrontend'
import WorkGraphics from './Pages/WorkGraphics/WorkGraphics'
import './App.css'

function App() {
  window.onload = function() {
    animateCursor()
  }
    
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage/>}/>
        <Route path="/work" element={<Work/>}/>
        <Route path="/work/frontend" element={<WorkFrontend/>}/>
        <Route path="/work/ui" element={<WorkUI/>}/>
        <Route path="/work/graphics" element={<WorkGraphics/>}/>
      </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
