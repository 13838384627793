import React from 'react'
import About from '../../Components/Homepage/About';
import './Homepage.css';
import ArrowDown from '../../Components/Homepage/ArrowDown';
import Contact from '../../Components/Homepage/Contact';
import Footer from '../../Components/Homepage/Footer';
import Header from '../../Components/Homepage/Header';
import Main from '../../Components/Homepage/Main';
import Skills from '../../Components/Homepage/Skills';
import Socials from '../../Components/Homepage/Socials';
import Work from '../../Components/Homepage/Work';
import Cursor from '../../Components/Homepage/Cursor';
import animateCursor from '../../Components/Homepage/Cursor'
import Nav from '../../Components/Homepage/Nav';

window.onload = function() {
  animateCursor()
}


const Homepage = () => {
  return (
    <div>
      <Header />
      <Nav />
      <div className='header-background'></div>
      <Main />
      <Socials />
      <ArrowDown /> 
      <div className="space" id='about1'></div>
      <About />
      <div className="space" id='skills1'></div>
      <Skills />
      <div className="space" id='work1'></div>
      <Work />
      <div className="space" id='contact1'></div>
      <Contact />
      <Footer />
      <Cursor />
    </div>
  )
}

export default Homepage