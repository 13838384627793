import React from 'react'
import About from '../../Components/Homepage/About';
import './WorkFrontend.css';
import Contact from '../../Components/Homepage/Contact';
import Footer from '../../Components/Homepage/Footer';
import Header from '../../Components/Homepage/Header';
import Main from '../../Components/Homepage/Main';
import Socials from '../../Components/Homepage/Socials';
import Cursor from '../../Components/Homepage/Cursor';
import animateCursor from '../../Components/Homepage/Cursor';
import Nav from '../../Components/Homepage/Nav';

window.onload = function() {
  animateCursor()
}

const Homepage = () => {
  return (
    <div>
      <Header />
      <Nav />
      <div className='project-selector'>
        <h3>Quick Links</h3>
        <ul>
          <li><a href="#project-1">Project 1: Gusto Italia - React.JS, SASS</a></li>
          <li><a href="#project-2">Project 2: SeoSwift - NextJS, SASS</a></li>
        </ul>
      </div>
      <div className="project-container-all">
      <div id="project-1" className='project-container'>
          <a target='#' href='https://gustoitalia.netlify.app/'>Gusto Italia</a>
          <h1>Project 1: Gusto Italia</h1>
          <img src="../images/gustoitalia1.png"></img>
          <h2>An Overview</h2>
          <p>Crafted with meticulous attention to detail, the Gusto Italia website represents a harmonious blend of cutting-edge technology and design finesse. Powered by React.js and SCSS, this digital masterpiece epitomizes the epitome of a fully responsive web platform, ensuring optimal viewing experiences across a myriad of devices and screen sizes. Through the strategic utilization of React.js, dynamic content presentation seamlessly intertwines with smooth navigation, ushering users through a captivating digital journey characterized by fluid transitions and intuitive interactions.</p>
          <p>At the heart of the Gusto Italia website lies its commitment to delivering a visually captivating and seamless user experience. Leveraging the robust capabilities of React.js, each page is meticulously crafted to engage visitors with compelling visuals and informative content. From the enticing imagery of delectable Italian cuisine to the user-friendly interface facilitating effortless exploration, every element is meticulously curated to evoke a sense of culinary delight and sophistication.</p>
          <img src="../images/gustoitalia2.png"></img>
          <p>Furthermore, the utilization of SCSS adds an additional layer of sophistication to the Gusto Italia website's design language. Through meticulously organized and scalable styling, SCSS ensures consistency and coherence in visual presentation, fostering a cohesive brand identity that resonates with the essence of Italian culinary tradition. Whether it's the elegant typography or the meticulously curated color palette, every aspect of the design is carefully calibrated to evoke a sense of warmth, authenticity, and sophistication that epitomizes the Gusto Italia dining experience.</p>
          <p>In essence, the Gusto Italia website stands as a testament to the seamless integration of technology and design, where React.js and SCSS converge to create an immersive digital experience that transcends mere functionality. It's not just a website; it's a digital manifestation of Gusto Italia's culinary ethos—a place where technology and tradition intertwine to deliver an unforgettable journey through the flavors of Italy.</p>
          <h2>Technologies/Skills Used</h2>
          <ul>
            <li>React.js</li>
            <li>TypeScript</li>
            <li>SCSS</li>
            <li>Git</li>
          </ul>
      </div>
      <div className='project-selector'>
        <h3>Quick Links</h3>
        <ul>
          <li><a href="#project-1">Project 1: Gusto Italia - React.JS, SASS</a></li>
          <li><a href="#project-2">Project 2: SeoSwift - NextJS, SASS</a></li>
        </ul>
      </div>
      <div id="project-2" className='project-container'>
        <a target='#' href="https://seoswift.netlify.app/">SEO Swift</a>
          <h1>Project 2: SeoSwift</h1>
          <img src="../images/seoswift1.png"></img>
          <h2>An Overview</h2>
          <p>SEO Swift, an innovative SEO services website, has been expertly crafted using Next.js, underscoring a dedicated commitment to cutting-edge technology for optimal performance. At the heart of its architecture, Next.js empowers the website with swift page rendering and seamless navigation, elevating both user experience and search engine optimization (SEO) capabilities. This strategic utilization of Next.js not only ensures a highly responsive and dynamic interface but also plays a pivotal role in fostering engagement with visitors keen on exploring top-tier SEO services.</p>
          <img src="../images/seoswift2.png"></img>
          <p>The website's design is meticulously tailored to prioritize user-friendly navigation and concise content presentation. This intentional approach aims to effectively communicate the inherent value of SEO Swift's offerings, providing a seamless journey for visitors from exploration to conversion. The combination of sleek design elements and a focus on streamlined user interactions positions SEO Swift as a leader in delivering impactful and efficient SEO solutions.</p>
          <p>With Next.js as its core foundation, the SEO services website reflects a strategic amalgamation of technological prowess and user-centric design principles. This not only ensures user satisfaction but also strategically enhances search engine visibility, aligning seamlessly with SEO Swift's commitment to delivering results in the ever-evolving digital landscape. In essence, the website stands as a testament to a holistic approach that places equal importance on user satisfaction and cutting-edge technology.</p>
          <h2>Technologies/Skills Used</h2>
          <ul>
            <li>NextJS</li>
            <li>TypeScript</li>
            <li>SCSS</li>
            <li>Git</li>
          </ul>
      </div>
      </div>
      <Cursor />
    </div>
  )
}

export default Homepage