import React from 'react'
import './Work.css'
import Header from '../../Components/Homepage/Header'
import Nav from '../../Components/Homepage/Nav'
import Cursor from '../../Components/Homepage/Cursor'
const Work = () => {
  return (
    <div>
        <Header />
      <Nav />
      <div className='all-work'>
        {/* <h1>Projects</h1> */}
        <div className='show-work-type-mobile'>
            <h3>Quick Links:</h3>
            <ul>
                <li><a href="/work/frontend">view all frontend work</a></li>
                <li><a href="/work/ui">view all ui/ux work</a></li>
                <li><a href="/work/graphics">view all graphic design work</a></li>
            </ul>
        </div>
        <div className='work-type'>
            <h2>Frontend</h2>
            <a href="/work/frontend">view all frontend work</a>
            <div className='work-container'>
                <a href='https://gustoitalia.netlify.app/'>Gusto Italia</a>
                <img src='../images/gustoitalia1.png' />
                <p>Crafted with React.js and SCSS, the Gusto Italia website stands as a fully responsive masterpiece, ensuring a visually captivating and seamless user experience. Leveraging React.js facilitates dynamic content presentation and smooth navigation, while SCSS ensures organized and scalable styling, contributing to a visually pleasing and functionally rich online presence for the Italian restaurant.</p>
                <a href='https://seoswift.netlify.app/'>SEOSwift</a>
                <img src='../images/seoswift1.png' />
                <p>SEO Swift, an SEO services website, was skillfully developed using Next.js, showcasing a commitment to cutting-edge technology for optimal performance. The website's architecture, powered by Next.js, enables swift page rendering and seamless navigation, enhancing user experience and search engine optimization (SEO) capabilities. The implementation of Next.js ensures a responsive and dynamic interface, fostering engagement with visitors interested in SEO services. The website design prioritizes user-friendly navigation and concise content presentation to effectively communicate the value of SEO Swift's offerings. With Next.js at its core, this SEO services website reflects a strategic approach to both user satisfaction and search engine visibility.</p>
            </div>
            </div>
            <div className='work-type'>
            <h2>UI/UX</h2>
            <a href="/work/ui">view all ui/ux work</a>
            <div className='work-container'>
                <a href='https://www.figma.com/file/33VLw7jRZREfhu7x0E3J3C/WCA-Flow%2FWire-v4v4?type=design&node-id=0%3A1&mode=design&t=ujfSQKz9ghjN1BT0-1'>WCA App UI/UX</a>
                <img src='../images/image4.png' />
                <p>The project entails creating a mobile wallet app. Initial stages involve competitor analysis, brainstorming unique features, and defining user-aligned objectives. Crafting a consistent brand identity, wireframing for simplicity, and prototyping refine user interactions, laying a strong foundation for informed design decisions.</p>
                <a href='https://www.figma.com/file/LlbTLJBojE1MiayOpqx5LX/Untitled?type=design&node-id=0%3A1&mode=design&t=Gju6j6g8yMX0hdb3-1'>Code Camp UI/UX</a>
                <img src='../images/2wireframe.png' />
                <p>For this code camp website UI project, I delved into the world of children's technology education, generating creative ideas through collaborative brainstorming, and aligning website goals with young learners' needs. Establishing a vibrant visual identity involved crafting a playful color palette and user-friendly fonts. In the wireframing phase, Figma was utilized to prioritize both functionality and a visually appealing layout. Prototyping, featuring playful animations, engaged stakeholders for feedback, allowing iterative adjustments for an optimal and enjoyable user journey on this educational and captivating kids' coding camp website.</p>
            </div>
            </div>
            <div className='work-type'>
            <h2>Graphic Design</h2>
            <a href="/work/graphics">view all graphic design work</a>
            <div className='work-container'>
                <a href=''>Planet Refill Logo</a>
                <img src='../images/planet-refill1.png' />
                <p>The logo designed for "Planet Refill" embodies a commitment to sustainability through thoughtful graphic elements. A heart-shaped emblem captures the essence of Earth and love, while the parts convey a strong message of eco-friendliness and recycling. The clean and modern fonts used for the brand name "Planet Refill" add a touch of reliability and contemporary design. This graphic design encapsulates the brand's dedication to sustainability, creating an impactful visual representation that resonates with environmentally conscious consumers.</p>
                <a href=''>Drink Flyer</a>
                <img src='../images/drinkflyer2.png' />
                <p>Crafted for bold impact, the soft drink poster flyer boasts vibrant colors and dynamic imagery to capture attention. A central image of fizzing soda bursting from an icy glass draws the viewer's eye, evoking a sense of refreshing indulgence. Bold, sans-serif fonts in contrasting colors announce the brand name and key selling points, ensuring instant recognition and readability from a distance. With minimalist yet striking design elements, including bubbles and splashes, this poster flyer exudes energy and excitement, enticing consumers to quench their thirst with the featured soft drink.</p>
            </div>
            </div>
      </div>
    </div>
  )
}

export default Work