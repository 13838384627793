import React from 'react'
import About from '../../Components/Homepage/About';
import './WorkUI.css';
import Contact from '../../Components/Homepage/Contact';
import Footer from '../../Components/Homepage/Footer';
import Header from '../../Components/Homepage/Header';
import Main from '../../Components/Homepage/Main';
import Socials from '../../Components/Homepage/Socials';
import Cursor from '../../Components/Homepage/Cursor';
import animateCursor from '../../Components/Homepage/Cursor';
import Nav from '../../Components/Homepage/Nav';

window.onload = function() {
  animateCursor()
}

const Homepage = () => {
  return (
    <div>
      <Header />
      <Nav />
      <div className='project-selector'>
        <h3>Quick Links</h3>
        <ul>
          <li><a href="#project-1">Project 1: Wallet Mobile App UI</a></li>
          <li><a href="#project-2">Project 2: Code Camp Website UI</a></li>
        </ul>
      </div>
      <div className="project-container-all">
      <div id="project-1" className='project-container'>
        <a target='#' href="https://www.figma.com/file/33VLw7jRZREfhu7x0E3J3C/WCA-Flow%2FWire-v4v4?type=design&node-id=0%3A1&mode=design&t=ujfSQKz9ghjN1BT0-1">WCA Wallet App Wireframe</a>
          <a target='#' href="https://www.figma.com/file/ddonIAeghUDg3aDAmQV3wx/WCA-Prototype?type=design&node-id=0%3A1&mode=design&t=4zBcgia7ncoj8xYa-1">WCA Wallet App Prototype</a>
          <h1>Project 1: WCA Wallet Mobile App UI</h1>
          <img src="../images/image1.png"></img>
          <h2>Brainstorming and Ideation</h2>
          <p>In the project's nascent stages, I immersed myself in understanding the landscape of mobile wallet apps, aiming to tailor the experience to meet user needs and expectations. Key steps included:</p>
          <ul>
              <li>Conducting a thorough competitor analysis to identify strengths, weaknesses, and emerging trends.</li>
              <li>Engaging in collaborative brainstorming sessions to generate ideas for distinctive features and intuitive design elements.</li>
              <li>Clearly defining the objectives of the mobile wallet app, aligning them with the identified user needs and pain points.</li>
          </ul>
          <img src="../images/image2.png"></img>
          <h2>Brand Analysis:</h2>
          <p>Establishing a strong and coherent visual identity was a crucial aspect of the design process. This involved:</p>
          <ul>
              <li>Crafting a colour palette that resonated with the app's purpose, opting for calming blues to convey trust and security.</li>
              <li>Defining fonts, typography, and iconography that were consistent with the brand, fostering a cohesive visual language.</li>
              <li>Ensuring that the chosen branding elements aligned with the app's identity and contributed to a positive user perception.</li>
          </ul>
          <h2>Wireframing:</h2>
          <img src="../images/image3.png"></img>
          <p>With insights gathered from the brainstorming sessions, I transitioned to sketching wireframes, creating a blueprint for the app's layout and navigation. The wireframing process involved:</p>
          <ul>
              <li>Utilizing Figma to translate conceptual ideas into tangible wireframes.</li>
              <li>Prioritizing simplicity and functionality in the wireframes to create an intuitive user experience.</li>
              <li>Iterating on the wireframes based on internal feedback and usability considerations.</li>
          </ul>
          <h2>Prototyping:</h2>
          <img src="../images/image4.png"></img>
          <p>The transition from wireframes to interactive prototypes was a pivotal phase aimed at refining user interactions and collecting valuable feedback. Key aspects of the prototyping process included:</p>
          <ul>
              <li>Developing interactive prototypes using Figma and InVision to visualize user flows and transitions.</li>
              <li>Sharing prototypes with stakeholders, peers, and potential users to gather diverse perspectives.</li>
              <li>Incorporating feedback into subsequent iterations to refine the app's usability and address identified pain points.</li>
          </ul>
          <img src="../images/image5.png"></img>
          <p>These stages of the design process allowed me to lay a solid foundation, ensuring that the subsequent design decisions were informed by a deep understanding of user needs, functional requirements, and the desired brand identity.</p>
          {/* <img src="images/image6.png"></img> */}
          {/* <h2></h2>
          <p></p>
          <ul>
              <li></li>
              <li></li>
              <li></li>
          </ul> */}
      </div>
      <div className='project-selector'>
        <h3>Quick Links</h3>
        <ul>
          <li><a href="#project-1">Project 1: Wallet Mobile App UI</a></li>
          <li><a href="#project-2">Project 2: Code Camp Website UI</a></li>
        </ul>
      </div>
      <div id="project-2" className='project-container'>
        <a target='#' href="https://www.figma.com/file/LlbTLJBojE1MiayOpqx5LX/Untitled?type=design&node-id=0%3A1&mode=design&t=Gju6j6g8yMX0hdb3-1">LittleCode Wireframe</a>
          <a target='#' href="https://www.figma.com/file/LlbTLJBojE1MiayOpqx5LX/Untitled?type=design&node-id=1%3A567&mode=design&t=Gju6j6g8yMX0hdb3-1">LittleCode Prototype</a>
          <h1>Project 2: LittleCode Website UI</h1>
          <img src="../images/2prototype.png"></img>
          <h2>Brainstorming and Ideation:</h2>
          <p>Embarking on the design journey for a kids' coding camp website involved immersing myself in the world of technology education for young learners. The brainstorming and ideation process encompassed:</p>
          <ul>
              <li>Exploring the landscape of educational websites catering to children.</li>
              <li>Collaborating on brainstorming sessions to generate creative ideas for engaging features and a playful design.</li>
              <li>Clearly defining the goals of the coding camp website, aligning them with the educational needs and interests of young participants.</li>
          </ul>
          <h2>Brand Analysis:</h2>
          <p>Establishing a vibrant and child-friendly visual identity was fundamental to the success of the coding camp website. The brand analysis process involved:</p>
          <ul>
              <li>Crafting a color palette that resonated with the energy of childhood, using bright and playful colors.</li>
              <li>Defining fonts and iconography that were not only visually appealing but also easy for young learners to comprehend.</li>
              <li>Ensuring that the chosen branding elements contributed to a positive and exciting perception of the coding camp, creating an atmosphere of both learning and fun.</li>
          </ul>
          <img src="../images/2wireframe.png"></img>
          <h2>Wireframing:</h2>
          <p>To translate conceptual ideas into a tangible web layout, I moved on to sketching wireframes that emphasized both fun and functionality. Key steps in the wireframing process included:</p>
          <ul>
              <li>Utilizing Figma, to create wireframes that laid the groundwork for the website's structure.</li>
              <li>Prioritizing a user-friendly and visually appealing layout, considering the unique needs of a younger audience.</li>
              <li>Iterating on the wireframes based on internal feedback and considerations of age-appropriate design elements.</li>
          </ul>
          <img src="../images/2wireframe1.png"></img>
          <h2>Prototyping:</h2>
          <p>The transition from wireframes to interactive prototypes played a vital role in refining user interactions and ensuring an engaging online experience for kids. The prototyping process involved:</p>
          <ul>
              <li>Developing interactive prototypes using Figma, incorporating playful animations and engaging transitions.</li>
              <li>Sharing prototypes with parents, educators, and potential young users to gather insights into usability and excitement.</li>
              <li>Iteratively adjusting the design based on received feedback to create an optimal and enjoyable user journey.</li>
          </ul>
          {/* <img src="images/2wireframe.png"></img> */}
          <p>These early design stages laid the groundwork for a kids' coding camp website that is not only educational but also captivating, fostering an environment where young minds can explore the wonders of coding in a delightful and accessible manner.</p>
      </div>
      </div>
      {/* <Contact /> */}
      <Cursor />
    </div>
  )
}

export default Homepage