import React from 'react'

const Work = () => {
  return (
    <div id="work" className='container'>
        <h1><span>03.</span>Work</h1>
        <h3>Some recent projects I've enjoyed working on can be found <a href='/work'>here</a></h3>
        
        <div className='work-container'>
            {/* <div id="work-cont" className='work-1'>
                <a target='#' href="https://www.figma.com/file/33VLw7jRZREfhu7x0E3J3C/WCA-Flow%2FWire-v4v4?type=design&node-id=0%3A1&mode=design&t=ujfSQKz9ghjN1BT0-1">WCA Wallet App Wireframe</a>
                <a target='#' href="https://www.figma.com/file/ddonIAeghUDg3aDAmQV3wx/WCA-Prototype?type=design&node-id=0%3A1&mode=design&t=4zBcgia7ncoj8xYa-1">WCA Wallet App Prototype</a>
                <a target='#' href="https://github.com/zombozz/flbird-mini"><img src="images/github.png"></img></a>
                <h3>Brainstorming and Ideation</h3>
                <p>In the project's nascent stages, I immersed myself in understanding the landscape of mobile wallet apps, aiming to tailor the experience to meet user needs and expectations. Key steps included:</p>
            </div> */}
            {/* <div id="work-cont" className='work-2'>
                <img src="../images/gustoitalia1.png" />
            </div> */}
            {/* <div id="work-cont" className='work-2'>
                <a target='#' href="https://omori-clone.netlify.app">omori-clone.netlify.app</a>
                <a target='#' href="https://github.com/zombozz/omori-clone-1"><img src="images/github.png"></img></a>
                <p>Clone of the website of one of my favourite games: Omori. I had a lot of fun recreating this website and its unique features. Original site link: <a href="https://www.omori-game.com/"><span>https://www.omori-game.com/</span></a></p>
            </div> */}
            {/* <div id="work-cont" className='work-2'>
                <a target='#' href="https://vlquiz.netlify.app">vlquiz.netlify.app</a>
                <a target='#' href="https://github.com/zombozz/quiz-finished"><img src="images/github.png"></img></a>
                <p>Quiz application utilising REST APIS, Redux Toolkit, React Router, features custom questions and answers, topic to choose from, and results at the end.</p>
            </div> */}
            {/* <div id="work-cont" className='work-3'>
                <a target='#' href="https://emergencybutton.io/">emergencybutton.io/</a>
                <a target='#' href="https://github.com/zombozz/TEB"><img src="images/github.png"></img></a>
                <p>Help site for assisting individuals with addictions through motivation and distractions. Work in progress.</p>
            </div> */}
            {/* <div id="work-cont" className='work-3'>
                <a target='#' href="https://art-gallery1.netlify.app">art-gallery1.netlify.app</a>
                <a target='#' href="https://github.com/zombozz/artwork-show"><img src="images/github.png"></img></a>
                <p>A site I built for my cousin to display her paintings: A personal website built using ReactJS with a simple grid gallery of images. *TEMPORARILY DOWN*</p>
            </div> */}
        </div>
    </div>
  )
}

export default Work