import React from 'react'
import About from '../../Components/Homepage/About';
import './WorkGraphics.css';
import Contact from '../../Components/Homepage/Contact';
import Footer from '../../Components/Homepage/Footer';
import Header from '../../Components/Homepage/Header';
import Main from '../../Components/Homepage/Main';
import Socials from '../../Components/Homepage/Socials';
import Cursor from '../../Components/Homepage/Cursor';
import animateCursor from '../../Components/Homepage/Cursor';
import Nav from '../../Components/Homepage/Nav';

window.onload = function() {
  animateCursor()
}

const Homepage = () => {
  return (
    <div>
      <Header />
      <Nav />
      {/* <div className='project-selector'>
        <h3>Quick Links</h3>
        <ul>
          <li><a href="#project-1">Project 1: Planet Refill Logo</a></li>
          <li><a href="#project-2">Project 2: Somedrink Poster Design</a></li>
        </ul>
      </div> */}
      <div className="graphics-container-all">
        
      <div id="project-1" className='graphics-container'>
          {/* <a target='#' href=''>Planet Refill Logo</a> */}
          <h1>Planet Refill Logo</h1>
          <img src="../images/planet-refill1.png"></img>
          {/* <p>The "Planet Refill" logo, featuring a heart-shaped emblem and thoughtful graphic elements, symbolizes a strong commitment to sustainability. With carefully designed components conveying an eco-friendly message and clean fonts, the logo serves as a contemporary representation, resonating with environmentally conscious consumers and reinforcing the brand's dedication to responsible practices.</p>   */}
      </div>
      <div id="project-2" className='graphics-container'>
          <h1>Somedrink Poster</h1>
          <img src="../images/drinkflyer2.png"></img>
          {/* <p>Crafted for bold impact, the soft drink poster flyer uses vibrant colors and dynamic imagery to captivate. A central image of fizzing soda draws attention, while bold fonts announce the brand and key points for instant recognition. Minimalist yet striking design, including playful bubbles, exudes energy, enticing consumers to indulge in the featured soft drink.</p>  */}
      </div>
      <div id="project-2" className='graphics-container'>
          <h1>NGCC Logo</h1>
          <img src="../images/NGCClogo.png"></img>
          {/* <p>Crafted for bold impact, the soft drink poster flyer uses vibrant colors and dynamic imagery to captivate. A central image of fizzing soda draws attention, while bold fonts announce the brand and key points for instant recognition. Minimalist yet striking design, including playful bubbles, exudes energy, enticing consumers to indulge in the featured soft drink.</p>   */}
      </div>
      <div id="project-2" className='graphics-container'>
          <h1>Somedrink Poster 2</h1>
          <img src="../images/drinkflyer3.png"></img>
          {/* <p>Crafted for bold impact, the soft drink poster flyer uses vibrant colors and dynamic imagery to captivate. A central image of fizzing soda draws attention, while bold fonts announce the brand and key points for instant recognition. Minimalist yet striking design, including playful bubbles, exudes energy, enticing consumers to indulge in the featured soft drink.</p>  */}
      </div>
      <div id="project-2" className='graphics-container'>
          <h1>Wokr Hero</h1>
          <img src="../images/wokr2.png"></img>
          {/* <p>Crafted for bold impact, the soft drink poster flyer uses vibrant colors and dynamic imagery to captivate. A central image of fizzing soda draws attention, while bold fonts announce the brand and key points for instant recognition. Minimalist yet striking design, including playful bubbles, exudes energy, enticing consumers to indulge in the featured soft drink.</p>   */}
      </div>
      <div id="project-2" className='graphics-container'>
          <h1>Autonoma Logo</h1>
          <img src="../images/autonomalogo.png"></img>
          {/* <p>Crafted for bold impact, the soft drink poster flyer uses vibrant colors and dynamic imagery to captivate. A central image of fizzing soda draws attention, while bold fonts announce the brand and key points for instant recognition. Minimalist yet striking design, including playful bubbles, exudes energy, enticing consumers to indulge in the featured soft drink.</p>   */}
      </div>
      </div>
      <Cursor />
    </div>
  )
}

export default Homepage